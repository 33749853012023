import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Loading } from 'components/Icons'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { axiosInstance as api } from 'services/api'
import UserStore from 'services/userLocalStorage'
import './styles.sass'
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material'
import Button from 'components/Button'
import { Row } from 'components/Layout'

function AssessmentPortal() {
  const { t } = useTranslation()
  const { auth } = useSelector((store) => store)
  const [loading, setLoading] = useState(false)
  const [, setMessage] = useState({})
  const [forms, setForms] = useState([])
  const portalId = window.endPoints.api.portalID

  let token = auth.signinToken == null ? UserStore.getToken() : auth.signinToken

  const sendToConsentPersonRespondent = async (id) => {
    setLoading(true)
    setMessage({})
    try {
      const { data: { url, externalAccessToken: externalToken } = {} } = await api.post(
        `/selfservice/forms/send/${portalId}/${id}`,
        { formEncryptedId: id },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      window.open(`${url}?token=${externalToken}`)
      setMessage({ type: 'success', message: t('edit.success.save') })
    } catch (error) {
      console.error({ error })
      setMessage({ type: 'error', message: t('genericError') })
    }
    setLoading(false)
  }

  const sendToResponse = async (id) => {
    setLoading(true)
    setMessage({})
    try {
      const { data: { url, externalAccessToken: externalToken } = {} } = await api.post(
        `/selfservice/forms/send/response/${portalId}/${id}`,
        { formEncryptedId: id },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      window.open(`${url}?token=${externalToken}`)
      setMessage({ type: 'success', message: t('edit.success.save') })
    } catch (error) {
      console.error({ error })
      setMessage({ type: 'error', message: t('genericError') })
    }
    setLoading(false)
  }

  const getForms = useCallback(async () => {
    setLoading(true)
    setMessage({})
    const requestOptions = {
      method: 'POST',
      url: `/selfservice/forms/${portalId}`,
      headers: { Authorization: `Bearer ${token}` },
      data: { formType: 'PORTAL', limit: 1000 },
    }
    try {
      const { data } = await api(requestOptions)
      setForms(data)
    } catch (error) {
      console.error({ error })
      setMessage({ type: 'error', message: t('genericError') })
    }
    setLoading(false)
  }, [portalId, t, token])

  useEffect(() => {
    getForms()
  }, [getForms])

  const renderResponses = (responses) => {
    if (responses && responses.length > 0) {
      return (
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls='panel1-content' id='panel1-header'>
              {t('label.view.responses')}
          </AccordionSummary>
          <AccordionDetails>
            <table
              className='table table-striped table-hover col-sm-11'
              style={{ marginLeft: '20px', marginTop: '10px', width: '100%' }}
            >
              <thead>
                <tr>
                  <th>{t('dm.default.creationDate')}</th>
                  <th>{t('dm.default.lastSendDate')}</th>
                  <th>{t('yourRequests.table.status')}</th>
                  {responses.find(response => response.calculatedScore !== null) && <th>{t('assessment.score')}</th>}
                  <th>{t('yourRequests.table.actions')}</th>
                </tr>
              </thead>
              <tbody>
                {responses.map((response) => (
                  <tr key={response.id}>
                    <td>{response.createdAt}</td>
                    <td>{response.lastSendDate}</td>
                    <td>{t(`dataMapping.dm-form.status.${response.sendRespondentStatus}`)}</td>
                    {response.calculatedScore !== null && <td>{response.calculatedScore}</td>}
                    <td>
                      <button
                        type='button'
                        title={t('label.view.responses')}
                        onClick={() => sendToResponse(response.id)}
                        className='btn btn-info td-button'
                      >
                        <i className='fas fa-eye' />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </AccordionDetails>
        </Accordion>
      )
    }
    return null
  }

  return (
    <section>
      <header className='pvt-content-header custom'>
        <h1
          style={{
            margin: '0 0 5px',
            fontSize: '24px',
            fontWeight: 'bold',
            color: 'var(--txt-surface)',
          }}
        >
          {t('label.self.service.assessment.create')}
        </h1>
        <p className='pvt-content-desc custom'>{t('label.self.service.assessment.description')}</p>
        <Row style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
              className="pvt-btn custom pvt-btn-primary undefined size-undefined"
              type="primary"
              label={t('yourRequests.button.refreshList')}
              onClick={() => getForms()}
          />
        </Row>
      </header>
      <div className='pvt-card pvt-card-100'>
        {loading ? (
          <Loading />
        ) : (
          <div className='dsar-form'>
            {forms.length > 0 ? (
              <div className='table-responsive'>
                <table className='table table-striped table-hover col-sm-12'>
                  <thead>
                    <tr>
                      <th>{t('assessment.form.table.title')}</th>
                      <th>{t('assessment.form.table.description')}</th>
                      <th>{t('assessment.form.table.action')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {forms.map((item) => (
                      <>
                      <tr key={item.id}>
                        <td>{item.name}</td>
                        <td>{item.description}</td>
                        <td className='td-buttons'>
                          {(!(item.responses && item.responses.length > 0) || item.acceptMultipleAnswersInPortal) && 
                            <button
                              type='button'
                              title={t('assessment.form.table.button.respondent')}
                              onClick={() => sendToConsentPersonRespondent(item.formId)}
                              className='btn btn-info td-button'
                            >
                              <i className='fas fa-reply' />
                            </button>
                          }
                        </td>
                      </tr>
                      {item.responses && item.responses.length > 0 &&
                        <tr key={`child-${item.responses.id}`}>
                            <td colspan="4">
                              {renderResponses(item.responses)}
                            </td>
                        </tr>}
                      </>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <p className='pvt-content-desc custom'>{t('assessment.form.assessment.respondent.not.found')}</p>
            )}
          </div>
        )}
      </div>
    </section>
  )
}

export default AssessmentPortal
