import React, { useEffect, useState, useMemo } from 'react';
import Popup from 'reactjs-popup';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import {
    getRequests,
    sendMessage,
} from 'store/reducers/requests.thunks';

import {
    getRequestsDetails
} from 'store/reducers/requestsDetails.thunks'

import {
    getPersonalData
} from 'store/reducers/personal-data.thunks';

import UserStore from 'services/userLocalStorage';
import { axiosInstance } from 'services/api';

import { Row } from 'components/Layout/Layout';
import Download from 'components/Icons/Download';
import Ok from 'components/Icons/Ok';
import Cancel from 'components/Icons/Cancel';
import Loading from 'components/Icons/Loading';
import { Col } from 'components/Layout/Layout';
import Label from 'components/Form/Label/Label';
import StatusLabel from './StatusLabel';
import Field from 'components/Form/Field';
import Button from 'components/Form/Button';
import Alert from 'components/Alert';

import './styles.sass';
import { getRequestByProtocol } from 'routes/routes';
import MessageForm from './MessageForm';
import { getLanguageCodeByValue } from 'services/availableLanguages';

const endPoints = window.endPoints

function YourRequestDetail({ tokenByProtocol, onClickBackButton, protocol }) {
    const { t } = useTranslation();
    const history = useHistory();
    const params = useParams();
    const reduxDispatch = useDispatch();
    const { auth = {}, personalData = {}, requests = {}, requestsDetails = {}, geral } = useSelector((store) => store);
    const person = useMemo(() => {
        return personalData?.person?.name ? {
            name: personalData.person.name,
            document: personalData.person.document,
            documentType: personalData.person.documentType,
        } : {
            name: '',
            document: '',
            documentType: 0
        }
    }, [personalData])

    const [newMessage, setNewMessage] = React.useState(false)
    const [detail, setDetail] = useState({});
    const [showMessage, setShowMessage] = useState(false);
    const [indexModal, setIndexModal] = useState({ index: 0, complete: false });
    const [foundPersonalData, setFoundPersonalData] = useState(false);
    const [showDossie, setShowDossie] = useState({});
    const [submitingCloseRequest, setSubmitingCloseRequest] = useState(false);
    const emptyMessage = { type: '', message: '' };
    const [message, setMessage] = useState(emptyMessage);
    const [confirmCloseRequest, setConfirmCloseRequest] = useState(false);

    const arrayPathName = window.location.pathname.split('/')
    const isRequestByProtocol = arrayPathName[3] === getRequestByProtocol

    const MSGS_MODAL = [
        'yourRequestsDetail.modal.msgStart.downloadPersonalData',
        'yourRequestsDetail.modal.msgFetching.downloadPersonalData',
        'yourRequestsDetail.modal.msgFileReceived.downloadPersonalData'
    ]

    if (isRequestByProtocol && !tokenByProtocol) {
        console.log('Request SEM Dados')
        history.push('/request-by-protocol')
    }

    useEffect(loadForm, []);

    function loadForm() {
        if (auth.isAuthenticated || !!tokenByProtocol) {
            const allRequests = requests.requests;
            if (allRequests.length === 0) {
                reduxDispatch(getPersonalData(tokenByProtocol, true))
                reduxDispatch(getRequests(tokenByProtocol))
            }
        }

    }

    useEffect(() => {
        if (!requests.fetching && requests.requests && requests.requests.length > 0) {
            const allRequests = requests.requests;
            const _protocol = protocol ? protocol : params.protocol
            const result = allRequests.find(req => req.protocol === _protocol);
            setDetail(result);
            setTimeout(adjustEmail, 1500);
            const optionParams = {
                encryptedId: result.encryptedId,
                download: false
            }
            reduxDispatch(getRequestsDetails(optionParams, tokenByProtocol));
        }
    }, [requests.fetching]);

    useEffect(() => {
        if (!requestsDetails.fetching && typeof requestsDetails.requestsDetails === 'object') {
            try {
                setShowDossie(requestsDetails.requestsDetails.showDossie);
                if (Object.keys(requestsDetails.requestsDetails).length > 0) {
                    setFoundPersonalData(requestsDetails.requestsDetails.foundPersonalData);
                }

                if (typeof requestsDetails.requestsDetails.fileName === 'string') {
                    setIndexModal({ index: 2 });
                    donwnloadZipFile();
                }
            } catch (e) {
                console.log(e)
            }

        }
    }, [requestsDetails.fetching, detail]);

    function donwnloadZipFile() {

        const endPoints = window.endPoints
        const { fileName } = requestsDetails.requestsDetails

        const urlZip = `${process.env.REACT_APP_BASE_API}public/${endPoints.api.requests}Details/downloads/${fileName}`

        const a = document.createElement("a");
        a.href = urlZip
        // a.setAttribute("download", filename);
        a.style.display = 'none';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        const optionParams = {
            encryptedId: detail.encryptedId,
            download: false
        }
        reduxDispatch(getRequestsDetails(optionParams, tokenByProtocol));
    }

    function handleBackButtonClick(ev) {
        ev.preventDefault();
        if (onClickBackButton) {
            onClickBackButton()
        } else {
            history.push('/your-requests');
        }
    }

    function handleClosePopup() {
        setShowMessage(false);
        setIndexModal({ index: 0 });
    }

    function handleDownloadPersonalData(ev, complete = false) {
        ev.preventDefault();
        ev.stopPropagation();
        setShowMessage(true);
        setIndexModal({ index: 0, complete: complete });

    }

    function handleOkModalPersonalData(ev) {
        ev.preventDefault();
        ev.stopPropagation();

        const _complete = indexModal.complete;
        const _dossie = showDossie
        setIndexModal({ index: 1, complete: _complete });

        const optionParams = {
            encryptedId: detail.encryptedId,
            download: true,
            complete: indexModal.complete,
            dossie: _dossie
        }

        reduxDispatch(getRequestsDetails(optionParams, tokenByProtocol))

    }

    function handleOkDownloadData(ev) {

        ev.preventDefault();
        ev.stopPropagation();

        const optionParams = {
            encryptedId: detail.encryptedId,
            printPdfAndCsv: true,
            download: true
        }

        reduxDispatch(getRequestsDetails(optionParams, tokenByProtocol))

    }

    // function handleDownloadClick(ev, pathFile) {
    //     ev.preventDefault();
    //     setLoading(true);

    //     const token = UserStore.getToken();
    //     const options = {
    //         method: 'GET',
    //         responseType: 'blob',
    //         headers: { Authorization: `Bearer ${token}` },
    //     };

    //     axios(pathFile, options).then((response) => {
    //         const filename = pathFile.substr(pathFile.lastIndexOf('/') + 1);
    //         // const a = document.createElement("a");
    //         // a.href = URL.createObjectURL(response.data);

    //         // a.setAttribute("download", filename);
    //         // a.style.display = 'none';
    //         // document.body.appendChild(a);

    //         // a.click();
    //         // document.body.removeChild(a);
    //         window.open(URL.createObjectURL(response.data));
    //     });
    // }

    function adjustEmail() {
        const scriptJs = document.createElement('script');
        scriptJs.type = 'text/javascript';
        scriptJs.async = false;
        scriptJs.innerHTML =

            `var messages = document.getElementsByClassName("contentMessage");
        for(var i = 0; i < messages.length; i++)
        {
            try {
                if (messages[i].innerText.indexOf("respondeu:") > -1) {
                    var messageContent = messages[i].innerText.replace("<p>","").replace("</p>","").replace(/(\\r\\n|\\n|\\r)/gm,"");
                    var j = 0;

                    while ((j = messageContent.indexOf("==", j)) != -1) {
                            messageContent = messageContent.replace("==", "=");
                    }
                    j = 0;
                    while ((j = messageContent.indexOf("=C3=", j)) != -1) {
                            messageContent = messageContent.replace("=C3=", "%C3%");
                    }
                    j = 0;
                    while ((j = messageContent.indexOf("= ", j)) != -1) {
                            messageContent = messageContent.replace("= ", " ");
                    }
                    j = 0;
                    while ((j = messageContent.indexOf("=", j)) != -1) {
                            messageContent = messageContent.replace("=", " ")
                    }
                    messageContent = decodeURIComponent(messageContent);

                    messages[i].innerHTML = messageContent;
                }
            } catch (e){console.log(e);}
        }
        `;

        document.body.appendChild(scriptJs);
    }

    function handleDownloadClick(ev, pathFile) {
        ev.preventDefault();
        ev.stopPropagation();
        let token = UserStore.getToken();
        if (tokenByProtocol) {
            token = tokenByProtocol
        }
        const url = `${pathFile}?token=${token}`;
        window.open(url);
    }

    function handleCloseRequest() {
        setConfirmCloseRequest(false);
        setSubmitingCloseRequest(true);

        //envia a requisição para fechar o pedido
        const token = tokenByProtocol ? tokenByProtocol : UserStore.getToken();
        const requestOptions = {
            method: 'POST',
            url: `${endPoints.api.url}/${endPoints.api.closeRequest}/${detail.encryptedId}`,
            headers: {
                Authorization: `Bearer ${token}`
            }
        }

        axiosInstance(requestOptions)
            .then(() => {
                showMessageTimeout({ type: "success", message: t('closeRequest.success') })
                reduxDispatch(getRequests(tokenByProtocol))
            })
            .catch((e) => {
                console.log("erro close", e)
                if (e.response.status === 403 || e.response.status === 400) {
                    showMessageTimeout({ type: "error", message: t('closeRequest.error.400') });
                } else {
                    showMessageTimeout({ type: "error", message: t('closeRequest.error') });
                }
                reduxDispatch(getRequests(tokenByProtocol))
            })
            .finally(() => {
                setSubmitingCloseRequest(false);
            });
    }

    function showMessageTimeout(message) {
        setMessage(message);
        setTimeout(() => {
            setMessage(emptyMessage);
        }, 5000);
    }

    function submitMessage({ message, fileData }, callback) {
        const params = {
            requestId: detail?.encryptedId,
            message,
            fileData,
            languageCode: getLanguageCodeByValue(geral.language)
        }
        const callbackSendMessage = (data) => {
            if (data.status === 200) {
                callback()
                // Timeout para animação do Ok na tela MessageForm
                setTimeout(() => {
                    setNewMessage(false)
                    reduxDispatch(getRequests(tokenByProtocol))
                    showMessageTimeout({ type: "success", message: t('sendMessage.success') })
                }, 1500)
            }
        }
        reduxDispatch(sendMessage(params, callbackSendMessage, tokenByProtocol))
    }

    if (newMessage) {
        return (
            <MessageForm
                onSubmit={submitMessage}
                request={detail}
                onClickBackButton={() => setNewMessage(false)}
            />
        )
    }

    return (<>
        {requests.fetching && (
            <p><Loading /> {t('yourRequests.loading')}</p>
        )}

        {Object.keys(detail).length > 0 && (
            <>
                <section className="pvt-your-request custom">
                    <header className="pvt-content-header custom">
                        <Row position={['y-center', 'x-start']}>
                            <button
                                type="button"
                                className="pvt-btn2 custom"
                                onClick={handleBackButtonClick}
                            >
                                <i className="fas fa-arrow-left" aria-hidden="true"></i> &nbsp;
                                <span>{t('button.label.back')}</span>
                            </button>
                            <h1 className="pvt-content-title custom">{t('yourRequestDetail.title')}:</h1>
                            <span className="txt-side-title"> {detail.protocol}</span>
                        </Row>
                    </header>
                </section>

                <FeedbackMessage type={message.type} message={message.message} />

                <section className="pvt-request-detail pvt-card pvt-txt-card">
                    <Row position={['y-center', 'x-start', 'x-between']}>
                        <Col>
                            <Row >
                                <Label label={t('yourRequests.table.holderName')} />
                            </Row>
                            <Row>
                                {person.name}
                            </Row>
                        </Col>
                        <Col>
                            <Row>
                                <Label label={t('yourRequests.table.holderDocument')} />
                            </Row>
                            <Row>
                                {
                                    person.documentType === 1 ? 'CPF ' :
                                        person.documentType === 2 ? 'CNPJ ' : ''
                                }
                                {person.document}
                            </Row>
                        </Col>
                    </Row>
                    <Row position={['y-center', 'x-start', 'x-between']}>
                        <Col>
                            <Row>
                                <Label label={t('yourRequests.table.protocol')} />
                            </Row>
                            <Row>
                                {detail.protocol}
                            </Row>
                        </Col>

                        <Col>
                            <Row>
                                <Label label={t('yourRequests.table.requestType')} />
                            </Row>
                            <Row>
                                {detail.requestType}
                            </Row>
                        </Col>

                        <Col>
                            <Row>
                                <Label label={t('yourRequests.table.date')} />
                            </Row>
                            <Row>
                                {detail.requestDateFormated}
                            </Row>
                        </Col>
                        <Col>
                            <Row>
                                <Label label={t('yourRequests.table.prediction.response')} />
                            </Row>
                            <Row>
                                {detail.expirationDate}
                            </Row>
                        </Col>

                        <Col>
                            <Row>
                                <Label label={t('yourRequests.table.status')} />
                            </Row>
                            <Row>
                                <StatusLabel statusCode={detail.statusCode} status={detail.status} />
                            </Row>

                        </Col>

                    </Row>
                </section>

                {detail.statusCode !== 'CLOSED' && (
                    <section className="pvt-request-detail">
                        <Field>
                            <Row>
                                <Button
                                    className='btn btn-success'
                                    label={t('button.label.closeRequest')}
                                    onClick={() => setConfirmCloseRequest(true)}
                                    loading={submitingCloseRequest}
                                    loadingLabel={t('closeRequest.saving')}
                                    disabled={true}
                                />
                                <Popup
                                    open={confirmCloseRequest}
                                    onClose={() => setConfirmCloseRequest(false)}
                                    position="right center"
                                    modal
                                >
                                    {close =>
                                        <div className="popup">
                                            <div className="popup-content">
                                                <h5>{t('closeRequest.confirm')}</h5>
                                            </div>                                            
                                            <div className=''>
                                                <div className='d-inline-flex' >
                                                    <button
                                                        id="btn-cancelModalCloseRequest"
                                                        type="button"
                                                        onClick={close}
                                                        className="btn btn-outline-danger pvt-cancelbutton">                                                        
                                                        <p><Cancel />{t('closeRequest.confirm.no')}</p>
                                                    </button>
                                                    <button
                                                        id="btn-okModalCloseRequest"
                                                        type="button"
                                                        onClick={handleCloseRequest}
                                                        className="btn pvt-okbutton btn-outline-success custom">
                                                        <p><Ok />{t('closeRequest.confirm.yes')}</p>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </Popup>
                            </Row>
                        </Field>
                    </section>
                )}

                {foundPersonalData && (
                    <section className="pvt-request-detail">
                        {
                            showDossie ?
                                <>
                                    <button
                                        id="btn-downloadPersonalData"
                                        type="button"
                                        onClick={handleDownloadPersonalData}
                                        className="pvt-downloadbutton custom"
                                    >
                                        <Download />
                                        {t('yourRequestsDetail.button.downloadDossie.simplified')}
                                    </button>
                                    <br></br>
                                    <br></br>
                                    <button
                                        id="btn-downloadPersonalData"
                                        type="button"
                                        onClick={ev => handleDownloadPersonalData(ev, true)}
                                        className="pvt-downloadbutton custom"
                                    >
                                        <Download />
                                        {t('yourRequestsDetail.button.downloadDossie.complete')}
                                    </button>
                                </>
                                :
                                <button
                                    id="btn-downloadPersonalData"
                                    type="button"
                                    onClick={handleDownloadPersonalData}
                                    className="pvt-downloadbutton custom"
                                >
                                    <Download />
                                    {t('yourRequestsDetail.button.downloadPersonalData')}
                                </button>
                        }


                        <Popup
                            open={showMessage}
                            onClose={handleClosePopup}
                            closeOnDocumentClick={!requestsDetails.fetching && showMessage}
                            position="right center"
                            modal
                        >
                            {close =>
                                <div className="popup">
                                    {!requestsDetails.fetching && (
                                        <div className="close">
                                            <a onClick={close}>&times;</a>
                                            <br />
                                        </div>
                                    )}

                                    <div className="popup-content">
                                        {t(MSGS_MODAL[indexModal.index])}
                                    </div>
                                    <br />
                                    {requestsDetails.fetching && showMessage && (
                                        <Loading />
                                    )}

                                    <div>
                                        {(indexModal.index === 0) && (
                                            <Row position={['y-center', 'x-center', 'x-between']}>
                                                <button
                                                    id="btn-okModalPersonalData"
                                                    type="button"
                                                    onClick={handleOkModalPersonalData}
                                                    className="pvt-okbutton custom">
                                                    <Ok />
                                                    {t('yourRequestsDetail.modal.button.ok.downloadPersonalData')}
                                                </button>
                                                <button
                                                    id="btn-cancelModalPersonalData"
                                                    type="button"
                                                    onClick={close}
                                                    className="pvt-cancelbutton custom">
                                                    <Cancel />
                                                    {t('yourRequestsDetail.modal.button.cancel.downloadPersonalData')}
                                                </button>
                                            </Row>
                                        )}
                                    </div>
                                </div>
                            }
                        </Popup>
                    </section>
                )}
                {
                    !showDossie &&
                    <section className="pvt-request-detail">
                        <button
                            id="btn-okModalPersonalData"
                            type="button" style={{ marginLeft: 'initial', padding: 'inherit' }}
                            onClick={handleOkDownloadData}
                            className="pvt-okbutton custom">
                            <Download />
                            {t('yourRequestsDetail.modal.button.downloadPersonalDataPdfCsv')}
                        </button>
                    </section>
                }
                <section className="pvt-request-detail-replies pvt-card pvt-txt-card">
                    <Row position={['y-center', 'x-start', 'x-between']}>
                        {detail.statusCode === 'REJECT' ?
                            <Col className="sender">
                                <label className="title">{t('yourRequests.label.justify.reject')}: </label> &nbsp;
                                <Row className="pvt-label-justify-reject" style={{ flexDirection: 'column' }}>
                                    {detail?.justfyReject ?
                                        detail?.justfyReject.replace(/"/g, '').split('\\n').map((line, index) => (
                                            <div key={index}>{line}</div>
                                        ))
                                        : ''
                                    }
                                </Row>
                            </Col>
                            : null
                        }
                    </Row>
                    <Row position={['y-center', 'x-start', 'x-between']}>
                        {detail.replies.length > 0 ?
                            <div className="title">{t('yourRequests.label.replies')}:</div>
                            : null
                        }
                        {detail.statusCode === 'REPLIED' && (
                            <Button
                                label={t('yourRequests.button.sendMessage')}
                                onClick={() => setNewMessage(true)}
                                // loading={true}
                                // loadingLabel="Carregando"
                                disabled={true}
                            />
                        )}
                    </Row>
                    <br />
                    {detail.replies.map(rep => (
                        <div key={rep.messageDateFormated} className="pvt-request-detail-reply-container">
                            <Row position={['y-center', 'x-start']}>
                                <Col className="sender">
                                    <label>{t('yourRequests.label.senderType')}: </label> &nbsp;
                                    {t(`yourRequests.label.${rep.senderType}`)}
                                </Col>

                                <Col>
                                    {rep.messageDateFormated}
                                </Col>
                            </Row>

                            <Row position={['y-center', 'x-start']}>
                                <Col className="reply-message">
                                    <Row>
                                        <Label label={`${t('yourRequests.label.message')}:`} />
                                    </Row>

                                    <Row><div className="contentMessage" dangerouslySetInnerHTML={{ __html: rep.message }}></div></Row>

                                </Col>
                            </Row>

                            {rep.pathFile && (
                                <Row position={['y-center', 'x-start']}>
                                    <Col className="reply-attachment">
                                        <a className="download-link" onClick={ev => handleDownloadClick(ev, rep.pathFile)}>
                                            {t('yourRequests.label.downloadFile')}
                                        </a>
                                    </Col>
                                </Row>
                            )}
                        </div>
                    ))}
                </section>
            </>
        )}
    </>);

}

export default YourRequestDetail;

const FeedbackMessage = ({ type, message }) => {

    if (!message) {
        return null;
    }
    return (
        <div className="pvt-msg-space">
            <Field>
                <Alert
                    align="center"
                    size="full"
                    type={type}
                    text={
                        <>
                            <b>{message}</b>
                        </>
                    }
                />
            </Field>
        </div>
    );
}